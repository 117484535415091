import React, { Suspense, lazy } from 'react';
import { Switch, Route, Redirect, matchPath } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { get } from 'lodash';
import { Spin } from 'antd';

import DefaultLayout from './layout';

const ContainerReleaseOrder = lazy(() =>
  import('./containers/ContainerReleaseOrder'),
);
const ContainerReleaseOrderCreate = lazy(() =>
  import('./containers/ContainerReleaseOrder/Create'),
);
const ContainerReleaseOrderDetail = lazy(() =>
  import('./containers/ContainerReleaseOrder/Detail'),
);
const Wallet = lazy(() => import('./containers/Wallet'));
const WalletCreate = lazy(() => import('./containers/Wallet/Create'));
const Invoice = lazy(() => import('./containers/Invoice/Record'));
const Expense = lazy(() => import('./containers/Invoice/Expense'));
const ExpenseLook = lazy(() => import('./containers/Invoice/Look'));
const PageNotFound = lazy(() => import('./containers/Exception/PageNotFound'));
const ContainerStaffManagement = lazy(() =>
  import('./containers/ContainerAccountManagement/StaffManagement'),
);
const ContainerStaffOperating = lazy(() =>
  import(
    './containers/ContainerAccountManagement/StaffManagement/StaffOperating'
  ),
);
const ContainerRolePermissions = lazy(() =>
  import('./containers/ContainerAccountManagement/RolePermissions'),
);
const ContainerRoleOperating = lazy(() =>
  import(
    './containers/ContainerAccountManagement/RolePermissions/RoleOperating'
  ),
);
const ContainerAddRoleOperating = lazy(() =>
  import(
    './containers/ContainerAccountManagement/RolePermissions/RoleOperating/role/add'
  ),
);
const ContainerGateInOrder = lazy(() =>
  import('./containers/ContainerGateInOrder'),
);
const ContainerGateInOrderCreate = lazy(() =>
  import('./containers/ContainerGateInOrder/Create'),
);
const ContainerGateInOrderDetail = lazy(() =>
  import('./containers/ContainerGateInOrder/Detail'),
);
// 余额管理
const BalanceManageList = lazy(() => import('./containers/Balance/List'));
// 余额充值
const BalanceRecharge = lazy(() => import('./containers/Balance/Recharge'));
// 余额提现
const BalanceWithdrawal = lazy(() => import('./containers/Balance/Withdrawal'));
// 陆运订单列表
const RoadTransportList = lazy(() =>
  import('./containers/RoadTransportOrder/List'),
);
// 陆运订单详情
const RoadTransportDetail = lazy(() =>
  import('./containers/RoadTransportOrder/Detail'),
);
// 陆运订单详情 - 箱子tab
const RoadTransportDetailBoxTab = lazy(() =>
  import('./containers/RoadTransportOrder/Detail/BoxList'),
);
// 陆运订单详情 - 详情
const RoadTransportDetailDetail = lazy(() =>
  import('./containers/RoadTransportOrder/Detail/Detail'),
);
// 陆运订单详情 - 状态
const RoadTransportDetailStatus = lazy(() =>
  import('./containers/RoadTransportOrder/Detail/Status'),
);
// 陆运订单详情 - 变更
const RoadTransportDetailChange = lazy(() =>
  import('./containers/RoadTransportOrder/Detail/Change'),
);
// 司机管理 - 司机列表
const DirverManageList = lazy(() => import('./containers/Driver/DriverList'));
// 司机管理 - 添加司机
const DirverManageAdd = lazy(() => import('./containers/Driver/DriverAdd'));
// 账务明细
const Bill = lazy(() => import('./containers/Bill'));
// 代垫列表
const Substitutecushion = lazy(() => import('./containers/Substitutecushion'));

// 货运界
const FreightMarket = lazy(() => import('./containers/Freight/Market'));
const FreightPublish = lazy(() => import('./containers/Freight/Publish'));

const PaotuiList = lazy(() => import('./containers/Paotui/List'));
const PaotuiCreate = lazy(() => import('./containers/Paotui/Create'));
const PaotuiQuanList = lazy(() => import('./containers/Paotui/Coupon'));
const PaotuiTiYanList = lazy(() =>
  import('./containers/Paotui/Coupon/experience'),
);

const getInitialMenuPath = (menu) => get(menu, '0.menu.0.url', null);
export const RouteConfig = [
  {
    path: '/paotui',
    mate: {
      breadcrumb: {
        title: '跑腿',
      },
    },
    routes: [
      {
        path: 'list',
        breadcrumb: {
          title: '订单列表',
        },
        routes: [
          {
            path: '',
            component: PaotuiList,
          },
          {
            path: 'create',
            component: PaotuiCreate,
          },
        ],
      },
      {
        path: 'coupon',
        breadcrumb: {
          title: '优惠券',
        },
        routes: [
          {
            path: '',
            component: PaotuiQuanList,
          },
        ],
      },
      {
        path: 'experience',
        breadcrumb: {
          title: '体验金',
        },
        routes: [
          {
            path: '',
            component: PaotuiTiYanList,
          },
        ],
      },
      {
        path: 'addressbook',
        breadcrumb: {
          title: '地址簿',
        },
        routes: [
          {
            path: '',
            component: lazy(() => import('./containers/Paotui/AddressBook')),
          },
        ],
      },
    ],
  },
  {
    path: '/huoyunjie',
    mate: {
      breadcrumb: {
        title: '货运界',
      },
    },
    routes: [
      {
        path: 'market',
        mate: {
          breadcrumb: {
            title: '货源市场',
          },
        },
        routes: [
          {
            path: '',
            component: FreightMarket,
          },
        ],
      },
      {
        path: 'publish',
        mate: {
          breadcrumb: {
            title: '发布管理',
          },
        },
        routes: [
          {
            path: '',
            component: FreightPublish,
          },
          {
            path: 'outbound',
            mate: {
              breadcrumb: {
                title: '2.0精准货源',
              },
            },
            routes: [
              {
                path: '',
                component: lazy(() =>
                  import('./containers/Freight/OutBound/index'),
                ),
              },
              {
                path: 'create',
                component: lazy(() =>
                  import('./containers/Freight/OutBound/Create'),
                ),
                mate: {
                  breadcrumb: {
                    title: '创建订单',
                  },
                },
              },
              {
                path: 'copy/:id',
                component: lazy(() =>
                  import('./containers/Freight/OutBound/Create'),
                ),
                mate: {
                  breadcrumb: {
                    title: '复制订单',
                  },
                },
              },
              {
                path: 'copy/:id/back',
                component: lazy(() =>
                  import('./containers/Freight/OutBound/Create'),
                ),
                mate: {
                  breadcrumb: {
                    title: '返程订单',
                  },
                },
              },
              {
                path: 'copy/:id/luyun',
                component: lazy(() =>
                  import('./containers/Freight/OutBound/Create'),
                ),
                mate: {
                  breadcrumb: {
                    title: '陆运转单',
                  },
                },
              },
              {
                path: 'info/:id',
                component: lazy(() =>
                  import('./containers/Freight/OutBound/Info'),
                ),
                mate: {
                  breadcrumb: {
                    title: '订单详情',
                  },
                },
              },
            ],
          },
        ],
      },
      {
        path: 'outbound-sign',
        mate: {
          breadcrumb: {
            title: '签约订单',
          },
        },
        routes: [
          {
            path: '',
            component: lazy(() => import('./containers/Freight/OutBound/sign')),
          },
        ],
      },
      {
        path: 'basic-data',
        mate: {
          breadcrumb: {
            title: '基础数据',
          },
        },
        routes: [
          {
            path: '',
            component: lazy(() => import('./containers/Freight/BasicData')),
          },
        ],
      },
    ],
  },
  {
    path: '/order',
    mate: {
      breadcrumb: {
        title: '订单管理',
      },
    },
    routes: [
      {
        path: '/containerRelease',
        mate: {
          breadcrumb: {
            title: '放箱订单',
          },
        },
        routes: [
          {
            path: '',
            component: ContainerReleaseOrder,
          },
          {
            path: '/create',
            component: ContainerReleaseOrderCreate,
            mate: {
              breadcrumb: {
                title: '创建放箱订单',
              },
            },
          },
          {
            path: '/detail/:id',
            component: ContainerReleaseOrderDetail,
            // mate: {
            //   breadcrumb: {
            //     title: '放箱订单详情',
            //   },
            // },
          },
        ],
      },
      {
        path: '/containerGateIn',
        mate: {
          breadcrumb: {
            title: '集港订单管理',
          },
        },
        routes: [
          {
            path: '',
            component: ContainerGateInOrder,
          },
          {
            path: '/create',
            component: ContainerGateInOrderCreate,
            mate: {
              breadcrumb: {
                title: '创建集港订单',
              },
            },
          },
          {
            path: '/eclpDetails/:hash',
            component: ContainerGateInOrderCreate,
            mate: {
              breadcrumb: {
                title: '创建集港订单',
              },
            },
          },
          {
            path: '/detail/:id',
            component: ContainerGateInOrderDetail,
            // mate: {
            //   breadcrumb: {
            //     title: '订单详情',
            //   },
            // },
          },
          {
            path: '/finance/detail/:id',
            component: ContainerGateInOrderDetail,
          },
        ],
      },
      {
        path: 'road/transport',
        mate: {
          breadcrumb: {
            title: '陆运订单管理',
          },
        },
        routes: [
          {
            path: '',
            component: RoadTransportList,
          },
          {
            path: ':dsphOrderId',
            component: RoadTransportDetail,
            routes: [
              {
                path: '',
                component: RoadTransportDetailBoxTab,
                routes: [
                  {
                    path: 'detail',
                    component: RoadTransportDetailDetail,
                    mate: {
                      breadcrumb: {
                        title: '订单信息',
                        replace: true,
                      },
                    },
                  },
                  {
                    path: 'status',
                    component: RoadTransportDetailStatus,
                    mate: {
                      breadcrumb: {
                        title: '订单状态',
                        replace: true,
                      },
                    },
                  },
                  {
                    path: 'change',
                    component: RoadTransportDetailChange,
                    mate: {
                      breadcrumb: {
                        title: '订单变更',
                        replace: true,
                      },
                    },
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        path: 'substitutecushion/list',
        mate: {
          breadcrumb: {
            title: '代垫列表',
          },
        },
        routes: [
          {
            path: '',
            component: Substitutecushion,
          },
        ],
      },
    ],
  },
  {
    path: '/driver',
    routes: [
      {
        path: '',
        mate: {
          breadcrumb: {
            title: '司机管理',
          },
        },
        routes: [
          {
            path: '/list',
            component: DirverManageList,
            mate: {
              breadcrumb: {
                title: '司机管理',
              },
            },
          },
          {
            path: 'add',
            component: DirverManageAdd,
            mate: {
              breadcrumb: {
                title: '新建司机',
              },
            },
          },
        ],
      },
      {
        path: '/car',
        mate: {
          breadcrumb: {
            title: '车辆管理',
          },
        },
        routes: [],
      },
    ],
  },
  {
    path: '/finance',
    mate: {
      breadcrumb: {
        title: '财务管理',
      },
    },
    routes: [
      {
        path: 'wallet',
        mate: {
          breadcrumb: {
            title: '钱包管理',
          },
        },
        routes: [
          {
            path: '',
            component: Wallet,
          },
          {
            path: 'create',
            routes: [
              {
                path: '/:packingPortType',
                component: WalletCreate,
              },
              {
                path: '/:id/:packingPortType',
                component: WalletCreate,
              },
            ],
          },
        ],
      },
      {
        path: '/invoice',
        routes: [
          {
            path: '',
            component: Invoice,
            mate: {
              breadcrumb: {
                title: '发票记录',
                replace: true,
              },
            },
          },
          {
            path: '/expense',
            component: Expense,
            mate: {
              breadcrumb: {
                title: '费用确认',
                replace: true,
              },
            },
          },
          {
            path: '/look/:id',
            component: ExpenseLook,
            mate: {
              breadcrumb: {
                title: '发票详情',
              },
            },
          },
        ],
      },
      {
        path: '/balance',
        routes: [
          {
            path: '',
            component: BalanceManageList,
            mate: {
              breadcrumb: {
                title: '余额管理',
                replace: true,
              },
            },
          },
          {
            path: '/recharge',
            routes: [
              {
                path: '',
                component: BalanceRecharge,
                mate: {
                  breadcrumb: {
                    title: '余额充值',
                  },
                },
              },
              {
                path: '/:rechargeId',
                component: BalanceRecharge,
                mate: {
                  breadcrumb: {
                    title: '充值修改',
                  },
                },
              },
            ],
          },
          {
            path: '/withdrawal',
            component: BalanceWithdrawal,
            mate: {
              breadcrumb: {
                title: '余额提现',
              },
            },
          },
        ],
      },
      {
        path: '/bill',
        component: Bill,
        mate: {
          breadcrumb: {
            title: '账务明细',
          },
        },
      },
      {
        path: 'huoyunjie',
        mate: {
          breadcrumb: {
            title: '货运界财务',
          },
        },
        routes: [
          {
            path: '',
            component: lazy(() => import('./containers/Freight/Bill')),
            meta: {},
          },
          {
            path: ':bill_id',
            component: lazy(() => import('./containers/Freight/Bill/detail')),
            meta: {
              breadcrumb: {
                title: '详情',
              },
            },
          },
        ],
      },
      {
        path: 'refund',
        mate: {
          // breadcrumb: {
          //   title: '',
          // },
        },
        routes: [
          {
            path: 'containerRelease',
            mate: {
              breadcrumb: {
                title: '放箱退费',
              },
            },
            routes: [
              {
                path: '',
                component: lazy(() =>
                  import('./containers/ContainerReleaseOrder/refund'),
                ),
                meta: {},
              },
              {
                path: ':id',
                component: lazy(() =>
                  import('./containers/ContainerReleaseOrder/refund/detail'),
                ),
                meta: {
                  breadcrumb: {
                    title: '详情',
                  },
                },
              },
            ],
          },
        ],
      },
    ],
  },
  {
    path: '/account',
    mate: {
      breadcrumb: {
        title: '系统管理',
      },
    },
    routes: [
      {
        path: 'staff',
        mate: {
          breadcrumb: {
            title: '员工管理',
          },
        },
        routes: [
          {
            path: '',
            component: ContainerStaffManagement,
          },
          {
            path: 'add',
            component: ContainerStaffOperating,
            mate: {
              breadcrumb: {
                title: '添加员工',
              },
            },
          },
          {
            path: 'edit/:id',
            component: ContainerStaffOperating,
            mate: {
              breadcrumb: {
                title: '编辑员工',
              },
            },
          },
        ],
      },
      {
        path: 'role',
        mate: {
          breadcrumb: {
            title: '角色权限',
          },
        },
        routes: [
          {
            path: '',
            component: ContainerRolePermissions,
          },
          {
            path: 'list/:id',
            component: ContainerRoleOperating,
            mate: {
              breadcrumb: {
                title: '员工分配',
              },
            },
          },
          {
            path: '/add/:id',
            component: ContainerAddRoleOperating,
            mate: {
              breadcrumb: {
                title: '添加员工',
              },
            },
          },
        ],
      },
    ],
  },
];
export const getRouteAllPath = (routes, pPath, result, breadcrumb) => {
  let match = false;
  routes.reduce((res, { path, routes: childRoutes, mate }) => {
    const routePath = `${pPath}${
      !path || path.startsWith('/') ? '' : '/'
    }${path}`;
    if (mate && mate.breadcrumb) {
      res[routePath] = { ...mate.breadcrumb, path: routePath };
      if (!match && routePath && breadcrumb) {
        match = matchPath(location.pathname, {
          path: routePath,
          exact: true,
          strict: false,
        });
        match &&
          breadcrumb.push({
            ...mate.breadcrumb,
            path: routePath,
            search: location.search,
          });
      }
    }
    if (childRoutes && !match) {
      let childMatch = getRouteAllPath(childRoutes, routePath, res, breadcrumb);
      if (!match && childMatch) {
        if (mate && mate.breadcrumb) {
          let itemPath = '';
          const noPathChild = childRoutes.some((item) => !item.path);
          if (noPathChild) itemPath = routePath;
          breadcrumb.push({
            ...mate.breadcrumb,
            path: itemPath,
          });
        }
        match = true;
      }
    }
    return res;
  }, result);
  return match;
};
function routeRender(routes, pPath = '') {
  return (
    <>
      {routes.map(({ path, component, routes: childRoutes }) => {
        const routePath = `${pPath}${
          !path || path.startsWith('/') ? '' : '/'
        }${path}`;
        const C = component;
        return (
          <React.Fragment key={routePath}>
            {component && (
              <Route
                path={routePath}
                render={(props) => (
                  <C {...props}>
                    {childRoutes && routeRender(childRoutes, routePath)}
                  </C>
                )}
                exact={childRoutes ? false : true}
              />
            )}
            {childRoutes && !component && routeRender(childRoutes, routePath)}
          </React.Fragment>
        );
      })}
    </>
  );
}

const Routes = () => {
  const menu = useSelector((state) => state.menu);
  const defaultRedirectMenuPath = getInitialMenuPath(menu);

  return (
    <DefaultLayout>
      <Suspense
        fallback={
          <div className="lazyLoading">
            <Spin size="large" />
          </div>
        }
      >
        <Switch>
          {defaultRedirectMenuPath ? (
            <Redirect from="/" to={defaultRedirectMenuPath} exact />
          ) : null}
          {routeRender(RouteConfig)}
          {menu.length ? <Route path="*" component={PageNotFound} /> : null}
        </Switch>
      </Suspense>
    </DefaultLayout>
  );
};

export default Routes;
